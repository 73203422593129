.dashboard {
  text-align: center;
  background: url("../../asset/gaming_BG.png");
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  min-height: 100vh;
  width: 100%;

  p {
    color: white;
  }

  select {
    font-size: 16px;
    font-weight: 700;
    color: orange;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 120px;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid orange;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 0.5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin-right: 10px;

    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat, repeat;
    /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
    background-position: right 0.7em top 50%, 0 0;
    /* icon size, then gradient */
    background-size: 0.65em auto, 100%;

    &:focus {
      outline: none;
      background-color: transparent;
    }
  }

  input {
    height: 40px;
    border: none;
    border-bottom: 3px solid orange;
    background: transparent;
    font-size: 18px;
    color: orange;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: orange;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: orange;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: orange;
    }
  }

  input[type="submit"] {
    cursor: pointer;
  }

  .ldsRoller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-top: 100px;

    div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
    }

    div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #fff;
      margin: -4px 0 0 -4px;
    }
    div:nth-child(1) {
      animation-delay: -0.036s;
    }
    div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }
    div:nth-child(2) {
      animation-delay: -0.072s;
    }
    div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }
    div:nth-child(3) {
      animation-delay: -0.108s;
    }
    div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }
    div:nth-child(4) {
      animation-delay: -0.144s;
    }
    div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }
    div:nth-child(5) {
      animation-delay: -0.18s;
    }
    div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }
    div:nth-child(6) {
      animation-delay: -0.216s;
    }
    div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }
    div:nth-child(7) {
      animation-delay: -0.252s;
    }
    div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }
    div:nth-child(8) {
      animation-delay: -0.288s;
    }
    div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  h1 {
    padding: 20px;
    color: white;
    font-size: 32px;
  }
}
