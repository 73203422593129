.cardBack {
  height: 300px;
  width: 300px;
  text-align: center;
  position: relative;
  background: url("../../asset/gaming_pattern.jpg");
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);
  background-position: center;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  color: orange;

  h4 {
    font-size: 18px;
  }

  button {
    background-color: #efefef;
    border-radius: 20px;
    border: none;
    position: absolute;
    font-weight: bold;
    bottom: 10px;
    padding: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    &:focus {
      outline: none;
    }
  }
}

.otherSide {
  display: none;
}

.flipindiag2br {
  -webkit-animation: flip-in-diag-2-br 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: flip-in-diag-2-br 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes flip-in-diag-2-br {
  0% {
    display: block;
    -webkit-transform: rotate3d(-1, 1, 0, -80deg);
    transform: rotate3d(-1, 1, 0, -80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
    transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}
@keyframes flip-in-diag-2-br {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, -80deg);
    transform: rotate3d(-1, 1, 0, -80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
    transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}
