nav {
    height: 10vh;
    width: 100%;
    background-color: orange;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .links {
        display: flex;
        width: 150px;
        justify-content: space-between;
        a {
            text-decoration: none;
            color: black;
            font-weight: bold;
        }
    }
}
