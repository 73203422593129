.about {
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 24px;
    color: orange;
    background: url("../../asset/gaming_BG.png");
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.7);

    a {
        text-decoration: none;
        color: orangered;
        font-size: 26px;
    }

    h1 {
        font-size: 32px;
    }

    article {
        text-align: center;

        span {
            background-color: black;
            font-weight: bold;
        }
    }
}